import { ethers } from 'ethers';
import { MerkleTree } from 'merkletreejs';
import { keccak256 } from "@ethersproject/keccak256";


const whitelist = [
    "0x099e0994873Bb1579Ba398D69D4E8b274Ab27126",
    "0x6aAAe7E2016E0Fe62dd791E96b2533968ce294ca",
    "0xB5145E865582295AF1F8567446E6F205282Cea18"
];

const leafNodes = whitelist.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });


export async function getRootHash() {

    const rootHash = merkleTree.getRoot();
    console.log('Whitelist Merkle Tree\n', merkleTree.toString());
    console.log("Root Hash: ", rootHash);
}

export function getProof(userAddress) {
    const claimingAddress = keccak256(userAddress);

    const hexProof = merkleTree.getHexProof(claimingAddress);
    return hexProof;

}

export async function checkWhitelist(userAddress) {


    const claimingAddress = keccak256(userAddress);
    const hexProof = merkleTree.getHexProof(claimingAddress);
    const rootHash = merkleTree.getRoot();

    const isWhitelisted = merkleTree.verify(hexProof, claimingAddress, rootHash);
    return isWhitelisted;
}