import React, { useEffect, useState, useRef } from 'react';
import { SiEthereum } from 'react-icons/si';
import { getEthUsdPrice } from '../utils/getETHPrice';
import * as ethers from 'ethers';
import arrow from '../assets/arrow.png';
import reload from '../assets/svg.png';
import { motion } from 'framer-motion';
import arrowLeft from '../assets/arrow-left.png';
import arrowWhite from '../assets/arrow-white.png';
import avaxLogo from '../assets/avaxlogo.svg';
import '../style.css';

import {
    Box,
    Flex,
    Image,
    Text,
    Link,
    useColorModeValue,
    useBreakpointValue,

    Circle,
    keyframes
} from '@chakra-ui/react';

import maxText from '../assets/Maxtext.png'
const shortAccount = (account) => {
    return account
        ? account.slice(0, 5) +
        '...' +
        account.slice(account.length - 5, account.length)
        : '';
};
const Dashboard = ({
    contractBalance,
    printedETH,
    maxBidor,
    biggestBid,
    remainingTime,
    oldMaxBidors,
    account,
    contract,
    presaleContractI,
    totalETHPrinted,
    _payOut
}) => {


    const menuBarBg = useColorModeValue("transparent", "gray.800");
    const menuItemHoverColor = useColorModeValue("#fff", "gray.300");


    const [activeCategory, setActiveCategory] = useState('Your earnings');
    const [ethUsdPrice, setEthUsdPrice] = useState(null);
    const [printedHistory, setPrintedHistory] = useState(null);
    const [historyData, setHistoryData] = useState(null);
    const [printedETHMaxBidor, setPrintedETHMaxBidor] = useState(null);

    const shortAddress = shortAccount(maxBidor);

    async function printEthUsdPrice(amountInETH) {
        try {
            const equivalentUSD = await getEthUsdPrice(amountInETH);
            return equivalentUSD.toFixed(2);
        } catch (error) {
            console.error('Error printing ETH/USD price:', error);
            return "Error";
        }
    }

    useEffect(() => {
        let isMounted = true;
        async function fetchEthUsdPrice() {
            const equivalentUSD = await printEthUsdPrice(printedETH);
            if (isMounted) {
                setEthUsdPrice(equivalentUSD);
            }
        }

        fetchEthUsdPrice();
        return () => { isMounted = false };
    }, [printedETH]);

    async function fetchPrintedHistory(adr) {
        const printedETHHistory = await contract._payOut(adr);


        return ethers.utils.formatEther(printedETHHistory);
    }

    useEffect(() => {
        async function fetchData() {
            const history = await fetchPrintedHistory(maxBidor);
            setPrintedETHMaxBidor(history);
        }

        fetchData();
    }, [maxBidor]);


    useEffect(() => {
        const fetchAllHistories = async () => {
            const fetchedData = {};

            for (const adr of oldMaxBidors) {
                const result = await fetchPrintedHistory(adr);
                fetchedData[adr] = Number(result).toFixed(3);
            }

            setHistoryData(fetchedData);
        };

        fetchAllHistories();
    }, [oldMaxBidors]);




    const [animationState, setAnimationState] = useState("");

    const animationKeyframes = keyframes`
    0% { transform: scale(1) rotate(0); border-radius: 20%; }
    25% { transform: scale(1) rotate(0); border-radius: 20%; }
    50% { transform: scale(1) rotate(360deg); border-radius: 50%; }
    75% { transform: scale(1) rotate(360deg); border-radius: 50%; }
    100% { transform: scale(1) rotate(0); border-radius: 20%; }
  `;



    const animation = `${animationKeyframes} 2s ease-in-out 1`;
    const MotionImage = motion(Image);
    const handleClick = () => {
        setAnimationState(animation);
        // Reset animation to allow it to trigger again
        setTimeout(() => setAnimationState(""), 1000);
    };
    const handleImageClick = () => {
        setActiveCategory('Your earnings');
    };

    const iconSize = useBreakpointValue({ base: '25px', md: '40px', lg: '40px' });

    let heightProps, overflowYProps;

    if (activeCategory === 'Current Max Bidor') {
        heightProps = { base: '70vh', ip: '75vh', lg: '62vh', sm: '85vh' };
        overflowYProps = 'scroll';
    } else if (activeCategory === 'MaxBidors History') {
        heightProps = { base: '70vh', ip: '75vh', lg: '62vh', sm: '85vh' };

        overflowYProps = 'scroll';
    } else if (activeCategory === 'Your earnings') {
        heightProps = { base: '70vh', ip: '75vh', lg: '62vh', sm: '85vh' };

    }
    const historyRef = useRef(null);


    return (
        <>
            <Box height={'fit-content'} width='100%' mt={'10px'}>

                <Flex
                    justifyContent={'center'} alignItems={'center'}>
                    {activeCategory == 'Your earnings' && (

                        <Text
                            fontFamily='TT Firs'
                            fontSize={{ base: '30px', sm: '30px', lg: '50px' }}
                            textAlign='center'
                            fontWeight={'700'}
                            color='white'

                        >DASHBOARD</Text>
                    )}
                    {(activeCategory === 'Current Max Bidor' || activeCategory === 'MaxBidors History') && (
                        <Text
                            fontFamily='TT Firs'
                            fontSize={{ base: '30px', sm: '30px', lg: '50px' }}
                            textAlign='center'
                            fontWeight={'700'}
                            color='white'

                        >HISTORY</Text>
                    )}

                    <Image

                        onClick={handleClick}
                        sx={{
                            animation: animationState,
                        }} cursor={'pointer'}
                        h={'84px'}
                        w={'84px'}
                        src={reload}


                    />

                </Flex>


            </Box>
            < Flex
                justifyContent={'center'}
            >




                <Box

                    px={{ base: '4%', sm: '7%', lg: '3%' }}
                    height={heightProps}
                    width={{ base: '80%', sm: '90%', md: '80%', lg: "70%", xl: '' }}
                    border='7px solid'
                    borderColor='#F8740E'
                    borderRadius={{ base: '50px', md: '60px', lg: '74px' }}  // rounded corner here
                    fontFamily="TT Firs"
                    overflowY={overflowYProps}
                    zIndex={'2'}
                    pb={'3%'}
                    id='main-container'
                >


                    <Box position="relative"


                    >
                        {activeCategory == 'Your earnings' && (
                            <Flex

                                justifyContent="center"
                                alignItems="center"
                                w={{ base: '', md: 'auto' }}
                                pt="20px"

                                className="menu-bar"
                                gap={{ base: '20px', md: '30px', sm: '', lg: '100px' }}

                            >


                                {['Your earnings', 'Current Max Bidor', 'MaxBidors History'].map((category, index) => (
                                    <Link
                                        key={index}
                                        fontSize={{ base: '13px', md: '14px', doublexl: '1.3rem' }}
                                        color={activeCategory === category ? 'white' : '#C2E6FF'}
                                        flex="1"
                                        px={{ base: '12px', lg: '50px' }}
                                        py={'5px'}
                                        border='7px solid'
                                        borderColor='#F8740E'
                                        borderRadius='74px'
                                        fontWeight={'700'}
                                        fontFamily={'TT Firs'}
                                        textAlign="center"
                                        display={category === 'MaxBidors History' ? { base: 'none', md: 'flex' } : 'flex'} // <-- added this line
                                        mt={'10px'}
                                        alignItems="center"
                                        boxSizing="border-box"
                                        justifyContent={'center'}

                                        transition="transform 0.3s ease"

                                        whiteSpace="nowrap"
                                        _hover={{
                                            color: menuItemHoverColor,
                                            cursor: 'pointer',
                                        }}

                                        onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            setActiveCategory(category);

                                            if (category === 'MaxBidors History') {
                                                setTimeout(() => {
                                                    const element = document.getElementById('History');
                                                    if (element) {
                                                        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                                    }
                                                }, 300);
                                            }
                                        }}




                                    >
                                        {category}
                                    </Link>

                                ))}
                            </Flex>
                        )}




                    </Box>


                    {activeCategory === 'Your earnings' && (
                        <>




                            <Flex

                                justifyContent={'center'} alignItems={'center'} pt={'40px'} gap={{ base: '30px', md: '40px', lg: '60px' }} w={'100%'}
                                flexDirection={{ base: "column", md: "row" }}                        >

                                <Box
                                    w={{ base: '30vh', lg: '35vh' }}
                                    h={{ base: '', md: '250px', lg: '300px' }}
                                    border='7px solid'
                                    borderColor='#F8740E'
                                    borderRadius={{ base: '40px', md: '60px', lg: '74px' }}
                                    display={'flex'} alignItems={'center'}
                                    flexDirection={'column'}
                                    fontWeight={'800'}

                                    fontFamily={'TT Firs'}
                                    textAlign="center"
                                    color={'white'}
                                    id='inner-div'

                                >
                                    <Text textAlign='center'
                                        mb={{ base: '15px', lg: '20px' }}
                                        mt={{ base: '15px', lg: '20px' }}
                                        fontSize={{ base: '20px', lg: '24px', md: '20px' }}
                                    >$MXB Balance</Text>
                                    <Image
                                        src={maxText}
                                        alt='balanceLogo'
                                        w={{ base: '100px', lg: '110px', md: '' }}
                                        h={{ base: '93px', lg: '93px', md: '93px' }}
                                        display='block'
                                        margin='0 auto'
                                        mb={{ base: '', lg: '5px' }}

                                    />
                                    <Text
                                        textAlign='center'
                                        fontSize={{ base: '24px', lg: '36px', md: '18px' }}
                                    >

                                        {isNaN(parseFloat(contractBalance)) ? '0.00 MXB' : parseFloat(contractBalance).toFixed(2) + ' $MXB'}
                                    </Text>
                                </Box>
                                <Box
                                    w={{ base: '30vh', lg: '35vh' }}
                                    h={{ base: '200px', md: '250px', lg: '300px' }}
                                    border='7px solid'
                                    borderColor='#F8740E'
                                    borderRadius={{ base: '40px', md: '60px', lg: '74px' }}
                                    display={'flex'} alignItems={'center'}
                                    flexDirection={'column'}
                                    fontWeight={'700'}
                                    fontFamily={'TT Firs'}
                                    textAlign="center"
                                    id='inner-div'

                                >
                                    <Text textAlign='center'
                                        mb={{ base: '10px', lg: '20px' }}
                                        mt={{ base: '15px', lg: '20px' }}
                                        fontSize={{ base: '20px', lg: '24px', md: '20px' }}
                                        color={'white'}>Printed ETH</Text>
                                    <Circle size={{ base: '50px', md: '75px', lg: '87px' }} bg="white"
                                        color="white"
                                        justifyContent={'center'}>

                                        {/* <Image
                                            src={avaxLogo} /> */}
                                            
                                    
                                        <SiEthereum 
                                        color='#1457FF' height={'50px'} w={'60px'}/>

                                    </Circle>


                                    <Text
                                        textAlign='center'
                                        fontSize={{ base: '24px', lg: '36px', md: '18px' }}
                                        color={'white'}>{printedETH ? parseFloat(printedETH).toFixed(2) + ' ETH' : 'Loading...'}
                                    </Text>

                                    <Text
                                        textAlign='center'
                                        fontSize='24px'
                                        color={'white'}>
                                        {ethUsdPrice ? '= ' + parseFloat(ethUsdPrice).toFixed(2) + '$' : 'Loading...'}
                                    </Text>

                                </Box>


                            </Flex>


                        </>
                    )}
                    {activeCategory === 'Your earnings' && (
                        <Flex justifyContent={'center'} gap={{ base: '20px', lg: '50px' }} alignItems={'center'} mt={'30px'} >
                            <Text
                                color='white'
                                fontFamily={'TT Firs Extra'}
                                fontSize={{ base: '15px', ip: '18px', md: '35px', lg: '47px' }}
                                whiteSpace={'nowrap'}

                            >BIGGEST BID</Text>
                            <Image
                                src={arrow}
                                height={{ base: '20px', lg: '30px' }}
                                width={{ base: '25px', lg: '39px' }}

                            />
                            <Text
                                color='white'
                                fontFamily={'TT Firs Extra'}
                                fontSize={{ base: '15px', ip: '18px', md: '35px', lg: '47px' }}
                                fontWeight={'800'}
                                whiteSpace={'nowrap'}>{biggestBid ? `${parseFloat(biggestBid).toFixed(2)} ETH` : 'Loading...'} </Text>
                        </Flex>

                    )}

                    {(activeCategory === 'Current Max Bidor' || activeCategory === 'MaxBidors History') && (

                        <Box alt='Container'

                        >

                            <Flex alignItems={'center'} gap={'10px'}>

                                <Image
                                    onClick={handleImageClick}
                                    src={arrowLeft}
                                    height='30px'
                                    w={'39px'}
                                    mt={'5px'}
                                    cursor={'pointer'}
                                />
                                <Text fontFamily={'TT Firs'}
                                    color={'white'}
                                >Back to Dashboard</Text>

                            </Flex>



                            <Text
                                h='10vh' color={'white'}
                                fontFamily='TT Firs Extra'
                                fontSize={{ base: '35px', md: '40px', lg: '48px', xl: '55px' }}
                                textAlign={'center'}
                                id='maxbidor'
                            > CURRENT MAX BIDDOR
                            </Text>

                            <Flex justifyContent={'center'}

                                alignItems={'center'}
                                gap={{ base: '30px', lg: '150px' }}
                                flexDirection={{ base: "column", md: "row" }}
                                h={'30%'}


                            >

                                <Flex fontFamily={'TT Firs Extra'}
                                    fontSize={{ base: '15px', md: '16px', lg: '24px' }}
                                    textAlign='center ' color={'white'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    mb={'10px'}
                                    mt={'20px'}
                                    whiteSpace={'nowrap'}
                                    gap={{ base: '40px', md: '100px', lg: '150px' }}
                                >

                                    <Text
                                        width={{ base: '130px', md: '100px', lg: '190px' }}
                                        maxWidth={'190px'}
                                        display={'inline-block'}
                                        overflow={'visible'}
                                        whiteSpace={'nowrap'}
                                        textAlign={'center'}
                                        fontSize={{ base: '13px', md: '14px', lg: '20px' }}
                                    > {shortAddress || 'Loading...'}</Text>
                                    <Image
                                        h={{ base: '32px', md: '42px' }}
                                        w={{ base: '32px', md: '42px' }}
                                        src={arrowWhite}
                                    />
                                    <Text whiteSpace={'nowrap'}>{biggestBid ? parseFloat(biggestBid).toFixed(2) + ' ETH' : 'Loading...'}</Text>


                                </Flex>






                            </Flex>
                            <Box fontFamily={'TT Firs Demi'}
                                fontSize={{ base: '15px', md: '16px', lg: '24px' }}
                                textAlign='center ' color={'white'}
                            >
                                <Text whiteSpace={'nowrap'}>Reset in : </Text>
                                <Text>{remainingTime}</Text>
                            </Box>
                            <Text
                                ref={historyRef}
                                pt={'10px'}
                                h='10vh' color={'white'}
                                fontFamily='TT Firs Extra'
                                fontSize={{ base: '35px', md: '40px', lg: '48px', xl: '55px' }}
                                textAlign={'center'}
                                id='History' > TOP BIDDORS HISTORY

                            </Text>
                            <Flex fontFamily={'TT Firs Extra'} fontSize={{ base: '10px', md: '16px', lg: '24px' }} textAlign='center ' color={'white'} flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                mt={'20px'}
                                mb={'20px'}
                                gap='30px'
                            >
                                <Text
                                    fontSize={{ base: '20px', md: '20px', lg: '30px' }} mt={{ base: '15px', lg: '' }}>Total ETH Printed : {parseFloat(totalETHPrinted).toFixed(2)} ETH</Text>
                                {
                                    oldMaxBidors.sort((a, b) => parseFloat(historyData[b] || 0) - parseFloat(historyData[a] || 0))
                                        .map((adr, index) => (
                                            <Flex
                                                whiteSpace={'nowrap'}
                                                fontSize={{ base: '15px', md: '16px', lg: '24px' }}
                                                textAlign={'center'}
                                                justifyContent={'center'}
                                                fontFamily={'TT Firs Extra'}
                                                gap={{ base: '40px', md: '100px', lg: '150px' }}

                                                alignItems={'center'}
                                            >

                                                <Text
                                                    width={{ base: '130px', md: '100px', lg: '190px' }}
                                                    maxWidth={'190px'}
                                                    display={'inline-block'}
                                                    overflow={'visible'}
                                                    whiteSpace={'nowrap'}
                                                    textAlign={'center'}
                                                    fontSize={{ base: '13px', md: '14px', lg: '20px' }}
                                                >
                                                    {shortAccount(adr)}
                                                </Text>
                                                <Image
                                                    h={{ base: '32px', md: '42px' }}
                                                    w={{ base: '32px', md: '42px' }}
                                                    src={arrowWhite}
                                                />
                                                <Box >
                                                    <Text>{historyData[adr] ? historyData[adr] + 'ETH' : 'Loading...'}</Text>
                                                </Box>
                                            </Flex>


                                        ))
                                }
                               


                            </Flex>
                        </Box >

                    )}





                </Box >


            </Flex >
        </>
    )
}

export default Dashboard;