import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';



const firebaseConfig = {
    apiKey: "AIzaSyCNDAN8YLIiEb8UDLrUzImpJZfghQDZFXU",
    authDomain: "maxbiddb.firebaseapp.com",
    databaseURL: "https://maxbiddb-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "maxbiddb",
    storageBucket: "maxbiddb.appspot.com",
    messagingSenderId: "524017926770",
    appId: "1:524017926770:web:89b27e11ee9dd4ad10f8eb"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export function getAllMaxBidorAddresses(callback) {
    const maxBidorAddressesRef = ref(database, 'maxBidorAddresses');

    onValue(maxBidorAddressesRef, (snapshot) => {
        const addressesData = snapshot.val();
        const addresses = Object.values(addressesData).map(data => data.address);
        callback(addresses);
    });
}


getAllMaxBidorAddresses(addresses => {
    console.log(addresses);
});
