import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    HStack,
    IconButton,
    useDisclosure,
    Stack,
    Text,
    Image,
    Button,
    keyframes,
    Link
} from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';
import { CgClose } from 'react-icons/cg';
import menuBtn from '../assets/menu-btn.png'
import closeBtn from '../assets/close-btn (1).png'
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../assets/m.png';
import { checkWhitelist } from '../utils/merkleProof';
const shortAccount = (account) => {
    return account
        ? account.slice(0, 4) +
        '...' +
        account.slice(account.length - 4, account.length)
        : '';
};

const Header = ({ account, setAccount, connectMetaMask }) => {
    const shortAddress = shortAccount(account);
    const [isWhitelisted, setIsWhitelisted] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); border-radius: 20%; }
  25% { transform: scale(2) rotate(0); border-radius: 20%; }
  50% { transform: scale(2) rotate(360deg); border-radius: 50%; }
  75% { transform: scale(1) rotate(360deg); border-radius: 50%; }
  100% { transform: scale(1) rotate(0); border-radius: 20%; }
`;



    const animation = `${animationKeyframes} 2s ease-in-out 1`;

    const handleDisconnect = () => {
        if (account) {
            setAccount('');
        } else {
            connectMetaMask();
        }

    };



    useEffect(() => {
        const checkIfWhitelisted = async () => {
            if (account) {
                const isAccountWhitelisted = await checkWhitelist(account);
                setIsWhitelisted(isAccountWhitelisted);
            }
        };

        checkIfWhitelisted();
    }, [account]);



    return (

        <Box height="70%" fontFamily='TT Firs' fontWeight={'700'} borderBottom={'4px solid #F8740E'}>
            <Flex alignItems={'center'}
                justifyContent={{ base: 'flex-end', md: 'center' }}
                fontSize={'20px'}
                height='100%'
                paddingLeft={{ base: '', md: '3%' }}
                w={'100%'}
            >





                <HStack
                    gap={{ base: '50px', lg: '80px', xl: '7vh', doublexl: '15vh' }}
                    as={'nav'}
                    display={{ base: 'none', md: 'flex' }}
                    color={'white'}

                >

                    <Image
                        boxSize={{ base: '100px', sm: '150px', md: '100px', lg: '100px' }}
                        objectFit='cover'
                        src={logo}
                        alt='logo'
                        cursor='pointer'
                        position='relative'

                        display={{ base: 'none', md: 'block' }}

                    />
                    <Text cursor="pointer" _hover={{ color: "white" }}>
                        <RouterLink to="/">Dashboard</RouterLink>
                    </Text>



                    <Text cursor="pointer" _hover={{ color: "white" }}>
                        <Link href="#" isExternal>
                            Rules
                        </Link>
                    </Text>
                    <Text cursor="pointer" _hover={{ color: "white" }}
                    >
                        <Link href="https://app.uniswap.org/swap" isExternal textDecoration={'none'}>
                            Buy $MXB
                        </Link>
                    </Text>
                    <Text cursor="pointer" _hover={{ color: "white" }}>
                        <Link href="https://dexscreener.com/base/" isExternal>
                            Chart
                        </Link>
                    </Text>







                    {/* {account ? ( */}
                    <Button
                        onClick={handleDisconnect}
                        width="150px"
                        height="45px"
                        borderRadius="15px"
                        borderWidth="2px"
                        borderColor="white"
                        backgroundColor="transparent"
                        color="white"
                        fontWeight="bold"
                        textDecoration="none"
                        ml="10px"
                        position="relative"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        _hover={{
                            bgColor: 'rgba(0, 0, 0, 0.188)'
                        }}
                        cursor='pointer'
                        fontFamily='TT Firs'
                    >
                        <Text className="account-address">{shortAddress || 'Connect Wallet'}</Text>

                    </Button>
                    {/* ) : null} */}


                </HStack>

                <IconButton
                    bg="transparent"
                    color="white"
                    fontSize={isOpen ? "30px" : "30px"}
                    _hover={{
                        height: '50px',
                        width: '50px'
                    }}
                    _focus={{
                        bg: "transparent"
                    }}
                    icon={isOpen ? <Image h='30px' w='30px' src={closeBtn} alt="close" /> : <Image h='30px' w='30px' src={menuBtn} alt="Menu" />}

                    aria-label={'Open Menu'}
                    display={{ md: 'none' }}
                    onClick={isOpen ? onClose : onOpen}
                />

            </Flex>

            {
                isOpen ? (
                    <Box pl="15px" mt={6} pb={6} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={6} color={'white'}>

                            <Text cursor="pointer" >
                                <Text cursor="pointer" _hover={{ color: "white" }}>
                                    <RouterLink to="/">Dashboard</RouterLink>
                                </Text>
                            </Text>


                          
                            <Text cursor="pointer" _hover={{ color: "white" }}
                            >
                                <Link href="https://app.uniswap.org/swap" isExternal textDecoration={'none'}>
                                    Buy $MXB
                                </Link>
                            </Text>
                            <Text cursor="pointer" _hover={{ color: "white" }}>
                                <Link href="https://dexscreener.com/base/" isExternal>
                                    Chart
                                </Link>
                            </Text>


                            <Button
                                onClick={handleDisconnect}
                                width="150px"
                                height="45px"
                                borderRadius="15px"
                                borderWidth="2px"
                                borderColor="white"
                                backgroundColor="transparent"
                                color="white"
                                fontWeight="bold"
                                textDecoration="none"
                                ml="10px"
                                position="relative"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                _hover={{
                                    bgColor: 'rgba(0, 0, 0, 0.188)'
                                }}
                                cursor='pointer'
                                fontFamily='TT Firs'
                                borderStyle={'double'}
                            >
                                <Text className="account-address">{shortAddress || 'Connect Wallet'}</Text>

                            </Button>

                        </Stack>
                    </Box>
                ) : null
            }
        </Box >


    );
}

export default Header;