import React, { useState, useEffect } from 'react';
import {

    Image
} from '@chakra-ui/react';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';
import {
    BrowserRouter as Router,
    Route,
    Routes,

} from 'react-router-dom';
import { startTimer } from '../utils/Timer';
import { getAllMaxBidorAddresses } from '../utils/maxBidorsHistory';
import contractABI from '../utils/abi/MaxBid.json';
import '../utils/smartContractInterface';
import '../utils/abi/ChainLink_ABI.json';
import maxTest from '../assets/Maxtext.png';
import Header from './Header';
import Dashboard from './Dashboard';
import Claim from './Claim';
import Footer from './Footer';
import { getBiggestBid, getMaxBidor, getNextReset, getTotalETHPrinted } from '../utils/smartContractInterface.js';
import presaleABI from '../utils/abi/presale.json';
import { Toaster } from "react-hot-toast";


const Container = () => {
    const [account, setAccount] = useState('');
    const [ethereumProvider, setEthereumProvider] = useState('');
    const [contract, setContract] = useState('');
    const [contractBalance, setContractBalance] = useState(null);
    const [printedETH, setPrintedETH] = useState('');
    const [maxBidor, setMaxBidor] = useState('');
    const [totalETHPrinted, setTotalETHPrinted] = useState('');
    const [biggestBid, setBiggestBid] = useState('');
    const [timestamp, setNextReset] = useState('');
    const [remainingTime, setRemainingTime] = useState('');
    const [oldMaxBidors, setOldMaxBidors] = useState([]);
    const [presaleContractI, setPresaleContract] = useState('');
    const [signer, setSigner] = useState('');


    const contractAddress = "0x28578181b247e886727F08FBA96D54828D73E849";

    const switchToArbitrum = async (ethereumProvider) => {
        try {
            await ethereumProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x2105' }],
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await ethereumProvider.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0x2105',
                                chainName: 'Base Mainnet',
                                nativeCurrency: {
                                    name: 'Base',
                                    symbol: 'ETH',
                                    decimals: 18,
                                },
                                rpcUrls: ['https://base-mainnet.g.alchemy.com/v2/V3aLafphJ7wjcQu9pWB67-mcK_2Bq91E'],
                                blockExplorerUrls: ['https://basescan.io'],
                            },
                        ],
                    });
                } catch (addError) {
                    console.error(addError);
                }
            } else {
                console.error(switchError);
            }
        }
    };

    // DESKTOP WALLET CONNECTION
    const connectMetaMask = async () => {
        const ethereumProvider = await detectEthereumProvider();
        setEthereumProvider(ethereumProvider);
        if (ethereumProvider) {
            try {
                const accounts = await ethereumProvider.request({ method: 'eth_requestAccounts' });
                setAccount(accounts[0]);
                await switchToArbitrum(ethereumProvider);
            } catch (error) {
                console.error(error);
            }
        } else {
            alert('Please Install Metamask.');
        }
    };



    useEffect(() => {
        const SetTimer = async () => {
            const intervalId = startTimer(timestamp, (remainingTime) => {
                setRemainingTime(remainingTime);
            });

            return () => clearInterval(intervalId);

        };

        SetTimer();
    }, [timestamp]);






    useEffect(() => {
        const initContract = async () => {
            try {
                if (!ethereumProvider) return;
                const provider = new ethers.providers.Web3Provider(ethereumProvider);
                const tokenContract = new ethers.Contract(contractAddress, contractABI, provider);
                setContract(tokenContract);
                const signer = provider.getSigner();
                setSigner(signer);
            } catch (error) {
                console.error("Error initializing contract:", error);
            }
        };

        initContract();
    }, [ethereumProvider]);


    useEffect(() => {
        const fetchContractDetails = async () => {
            const maxBidor = await getMaxBidor();
            setMaxBidor(maxBidor);
            const totalETHPrinted = await getTotalETHPrinted();
            setTotalETHPrinted(ethers.utils.formatEther(totalETHPrinted));
            const biggestBid = await getBiggestBid();
            setBiggestBid(ethers.utils.formatEther(biggestBid));
            const nextReset = await getNextReset();
            setNextReset(nextReset.toString());
        }
        fetchContractDetails();
    }, [])

    useEffect(() => {
        getAllMaxBidorAddresses(addresses => {
            if (addresses) {
                setOldMaxBidors(addresses);
            } else {
                console.error("Failed to fetch addresses from the database.");
            }
        });
    }, []);

    useEffect(() => {
        const fetchUserDetails = async () => {
            if (contract) {
                try {

                    const balance = await contract.balanceOf(account);

                    setContractBalance(ethers.utils.formatEther(balance));
                    const printed = await contract._payOut(account);
                    setPrintedETH(ethers.utils.formatEther(printed));
                } catch (error) {
                    console.error("Error fetching contract details:", error);
                }
            }
        };

        fetchUserDetails();
    }, [contract, account]);


    const handleAccountChange = async (accounts) => {
        if (accounts.length > 0) {
            setAccount(accounts[0]);

        }
    };

    useEffect(() => {
        const init = async () => {
            const ethereumProvider = await detectEthereumProvider();
            if (ethereumProvider) {
                ethereumProvider.on('accountsChanged', handleAccountChange);
                connectMetaMask(ethereumProvider);
            } else {
                alert('Please install MetaMask.');
            }
        };
        init();

        return () => {
            if (window.ethereum) {
                window.ethereum.removeListener('accountsChanged', handleAccountChange);
            }
        };
    }, []);

    // PRESALE TEST

    useEffect(() => {
        if (!signer) return;

        try {
            const presaleContractAddress = '0xc3C742421A344D12Ea9c6D49dAe7b5C4f0662E9F';

            if (!presaleABI) {
                console.error("Presale ABI is missing");
                return;
            }

            const presaleContract = new ethers.Contract(presaleContractAddress, presaleABI, signer);
            setPresaleContract(presaleContract);
        } catch (error) {
            console.error("Error initializing presale contract:", error);
        }
    }, [signer]);






    return (
        <Router>
            <>
                <Toaster
                    toastOptions={{
                        className: '',
                        style: {

                            background: '#a75200',
                            border: '3px solid #965201',
                            padding: '16px',
                            color: 'white',
                            marginRight: '30px'
                        },
                    }} />
                <Header account={account} setAccount={setAccount} connectMetaMask={connectMetaMask} />


                <Routes>
                    {/* <Route path="/claim" element={<Claim presaleContractI={presaleContractI} account={account} />} /> */}
                    <Route path="/" element={<Dashboard presaleContractI={presaleContractI} contract={contract} oldMaxBidors={oldMaxBidors} account={account} contractBalance={contractBalance} printedETH={printedETH} maxBidor={maxBidor} biggestBid={biggestBid} remainingTime={remainingTime} totalETHPrinted={totalETHPrinted} />} />
                </Routes>

                <Footer />
            </>
        </Router>
    );
}
export default Container;
