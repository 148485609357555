import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Link,
    Image
} from '@chakra-ui/react';
import { SiTwitter, SiTelegram, SiDiscord } from 'react-icons/si';
import X from '../assets/x-twitter.png';

function Footer() {
    return (
        <Flex direction="column" >
            <Box py='5' px={{ base: '4%', sm: '7%', lg: "20%" }} w={'100%'} flex="1">
                <Flex justifyContent={'center'} gap={'40px'}>
                    <Link
                        target="_blank"
                        fontSize="45px"
                        href="https://twitter.com/MaxBiddingBASE"
                        cursor={'pointer'}>


                        <Image
                            src={X}
                            h={'48px'}
                            w={'48px'}
                        />
                    </Link>
                    <Link
                        target="_blank"
                        fontSize="45px"
                        href="https://t.me/MAXBIDDINGBASE"
                        cursor={'pointer'}>

                        <SiTelegram
                            color='#C2E6FF' />
                    </Link>

                </Flex>


            </Box>
        </Flex>

    )
}
export default Footer;
