import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import '../style.css';


import Container from './Container'

const colors = {
  primary: {
    900: '#2B2C30',
    800: '#C2E6FF',
    700: '#cb7600',
    600: '#F6BD01',
    500: '#29EEFE',
    400: '#1F287A',
    300: '#171941'
  },
  secondary: {
    900: '#01111E',
    800: '#04082E'
  }
}

const styles = {


  global: {

    'html, body, root': {
      margin: '0',
      padding: '0',
      lineHeight: 'tall',
      fontSize: '16px',
      minHeight: '100vh',
      // bgImage: backgroundImage,

      bgGradient: "linear(180deg, rgba(0,143,253,1) 0%, rgba(0,82,254,1) 100%);",
      boxSizing: 'border-box'
    },
    a: {
      fontSize: "20px",
      textDecoration: "none"
    }
  },
}

const fonts = {
  fonts: {
    body: "TT Firs",
  }
}

const breakpoints = {
  sm: '360px',
  ip: '390px',
  md: '620px',
  lg: '960px',
  xl: '1200px',
  doublexl: '1536px',
}

const theme = extendTheme({ colors, styles, breakpoints, fonts });
function App() {

  return (
    <ChakraProvider theme={theme}>
      <Container />
    </ChakraProvider>
  );
}

export default App;

