import * as ethers from "ethers";
import aggregatorV3InterfaceABI from './abi/ChainLink_ABI.json';

async function getEthUsdPrice(amountInETH) {
    try {

        const provider = new ethers.providers.JsonRpcProvider(
            "https://base-mainnet.g.alchemy.com/v2/V3aLafphJ7wjcQu9pWB67-mcK_2Bq91E"
        );
        // Chainlink ETH/USD price feed contract address (on Mainnet)
        const chainlinkEthUsdAddress = "0x71041dddad3595F9CEd3DcCFBe3D1F4b0a16Bb70";


        const priceFeed = new ethers.Contract(chainlinkEthUsdAddress, aggregatorV3InterfaceABI, provider);
        const [, price, , ,] = await priceFeed.latestRoundData();

        const ethUsdPrice = price / 1e8;
        const equivalentUSD = amountInETH * ethUsdPrice;

        return equivalentUSD;
    } catch (error) {
        console.error('Error fetching ETH/USD price:', error);
        throw error;
    }
}

export { getEthUsdPrice };





